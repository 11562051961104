import React, { useEffect } from 'react';

const RedirectComponent = () => {
  useEffect(() => {
    // Obtener el valor del parámetro 'field65' de la URL
    const fieldValue = new URLSearchParams(window.location.search).get('field65');
    // Redireccionar a la URL deseada
    window.open(`https://paginasamarillas.lightning.force.com/${fieldValue}`, '_blank');
  }, []);

  return <div></div>;
};

export default RedirectComponent;
